import React from 'react';
import MoleculeLandingPage from './CO2LandingPage';
import TestContactOverlay from './TestContactOverlay';
import SimpleContactButton from './SimpleContactButton';
import './index.css';

function App() {
    return (
        <div className="App">
            <MoleculeLandingPage />
            {/* <SimpleContactButton /> */}
        </div>
    );
}

export default App;