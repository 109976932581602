import React from 'react';

const useResponsiveStyles = () => {
    const [styles, setStyles] = React.useState({
        button: {
            padding: '8px 16px',
            backgroundColor: 'transparent',
            color: '#A7D3E0',
            fontWeight: 600,
            borderRadius: '6px',
            border: '2px solid #A7D3E0',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            fontFamily: "'Montserrat', sans-serif",
            fontSize: 'calc(0.8rem + 0.5vw)',
            textShadow: '0 0 10px rgba(167, 211, 224, 0.5)',
            boxShadow: '0 0 15px rgba(167, 211, 224, 0.3)',
        }
    });

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setStyles(prevStyles => ({
                    ...prevStyles,
                    button: {
                        ...prevStyles.button,
                        fontSize: 'calc(1rem + 0.5vw)',
                    }
                }));
            } else {
                setStyles(prevStyles => ({
                    ...prevStyles,
                    button: {
                        ...prevStyles.button,
                        fontSize: 'calc(0.8rem + 0.5vw)',
                    }
                }));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return styles;
};

const SimpleContactButton = () => {
    const styles = useResponsiveStyles();

    const handleClick = () => {
        const subject = 'Kaiolabs Website Inquiry';
        const emailAddress = 'will@kaiolabs.com';
        const mailtolink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtolink;
    };

    return (
        <button
            onClick={handleClick}
            style={styles.button}
            onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#A7D3E0';
                e.target.style.color = 'black';
            }}
            onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#A7D3E0';
            }}
        >
            Contact Us
        </button>
    );
};

export default SimpleContactButton;