import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import BottomComponent from './BottomComponent'; // Import the BottomComponent
import SimpleContactButton from './SimpleContactButton'; // Import the new component

// Color variables
const CARBON_COLOR = 0xB0B0B0;  // Light Gray for Carbon
const OXYGEN_COLOR = 0xA7D3E0;   // Pastel Blue for Oxygen
const HYDROGEN_COLOR = 0xffffff; // White for Hydrogen
const BOND_COLOR = 0xC0C0C0;      // Silver for Bonds
// const BACKGROUND_COLOR = 0x000000; // Black Background
const PARTICLE_COLOR = 0xffffff;   // White Particles

const createMolecule = (isCO2) => {
    const molecule = new THREE.Group();
    if (isCO2) {
        // Create CO2 molecule
        const carbonGeometry = new THREE.SphereGeometry(0.4, 32, 32);
        const carbonMaterial = new THREE.MeshPhongMaterial({ color: CARBON_COLOR });
        const carbonAtom = new THREE.Mesh(carbonGeometry, carbonMaterial);

        const oxygenGeometry = new THREE.SphereGeometry(0.3, 32, 32);
        const oxygenMaterial = new THREE.MeshPhongMaterial({ color: OXYGEN_COLOR });
        const oxygenAtom1 = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
        const oxygenAtom2 = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
        oxygenAtom1.position.x = -1;
        oxygenAtom2.position.x = 1;

        const bondGeometry = new THREE.CylinderGeometry(0.03, 0.03, 1);
        const bondMaterial = new THREE.MeshPhongMaterial({ color: BOND_COLOR });

        // Double bonds for CO2
        const bond1 = new THREE.Group();
        const bond2 = new THREE.Group();

        for (let i = 0; i < 2; i++) {
            const subBond1 = new THREE.Mesh(bondGeometry, bondMaterial);
            const subBond2 = new THREE.Mesh(bondGeometry, bondMaterial);
            subBond1.position.y = i * 0.1 - 0.05;
            subBond2.position.y = i * 0.1 - 0.05;
            subBond1.rotation.z = Math.PI / 2;
            subBond2.rotation.z = Math.PI / 2;
            bond1.add(subBond1);
            bond2.add(subBond2);
        }

        bond1.position.x = -0.5;
        bond2.position.x = 0.5;

        molecule.add(carbonAtom, oxygenAtom1, oxygenAtom2, bond1, bond2);
    } else {
        // Create C2H4 molecule
        const carbonGeometry = new THREE.SphereGeometry(0.4, 32, 32);
        const carbonMaterial = new THREE.MeshPhongMaterial({ color: CARBON_COLOR });
        const carbonAtom1 = new THREE.Mesh(carbonGeometry, carbonMaterial);
        const carbonAtom2 = new THREE.Mesh(carbonGeometry, carbonMaterial);
        carbonAtom1.position.x = -0.5;
        carbonAtom2.position.x = 0.5;

        const hydrogenGeometry = new THREE.SphereGeometry(0.2, 32, 32);
        const hydrogenMaterial = new THREE.MeshPhongMaterial({ color: HYDROGEN_COLOR });
        const hydrogenAtoms = [];
        for (let i = 0; i < 4; i++) {
            const hydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
            hydrogenAtoms.push(hydrogen);
        }
        hydrogenAtoms[0].position.set(-1, 0.5, 0);
        hydrogenAtoms[1].position.set(-1, -0.5, 0);
        hydrogenAtoms[2].position.set(1, 0.5, 0);
        hydrogenAtoms[3].position.set(1, -0.5, 0);

        const bondGeometry = new THREE.CylinderGeometry(0.03, 0.03, 0.5);
        const bondMaterial = new THREE.MeshPhongMaterial({ color: BOND_COLOR });

        // Single bonds for C-H
        const chBonds = [];
        for (let i = 0; i < 4; i++) {
            const bond = new THREE.Mesh(bondGeometry, bondMaterial);
            chBonds.push(bond);
        }
        chBonds[0].position.set(-0.75, 0.25, 0);
        chBonds[1].position.set(-0.75, -0.25, 0);
        chBonds[2].position.set(0.75, 0.25, 0);
        chBonds[3].position.set(0.75, -0.25, 0);
        chBonds[0].rotation.z = Math.PI / 4;
        chBonds[1].rotation.z = -Math.PI / 4;
        chBonds[2].rotation.z = -Math.PI / 4;
        chBonds[3].rotation.z = Math.PI / 4;

        // Double bond for C=C
        const ccBond = new THREE.Group();
        for (let i = 0; i < 2; i++) {
            const subBond = new THREE.Mesh(bondGeometry, bondMaterial);
            subBond.position.y = i * 0.1 - 0.05;
            subBond.rotation.z = Math.PI / 2;
            ccBond.add(subBond);
        }

        molecule.add(carbonAtom1, carbonAtom2, ...hydrogenAtoms, ...chBonds, ccBond);
    }
    return molecule;
};

const TitleComponent = () => (
    <h1 style={{
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 'calc(2rem + 2vw)',
        fontWeight: 700,
        marginBottom: '0.5rem',
        textShadow: '0 0 10px rgba(255,255,255,0.5)',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        paddingTop: '1rem'
    }}>
        Kaio Labs
    </h1>
);

const MoleculeLandingPage = () => {
    const mountRef = useRef(null);
    const [isCO2, setIsCO2] = useState(true);
    const isDraggingRef = useRef(false);
    const previousMousePositionRef = useRef({ x: 0, y: 0 });
    const moleculeGroupRef = useRef(null);
    const particlesMeshRef = useRef(null);
    const currentMoleculeRef = useRef(null);
    const rotationRef = useRef(new THREE.Euler());
    const ROTATION_SPEED = 0.0005; // Significantly reduced rotation speed
    const lastTimeRef = useRef(0);

    useEffect(() => {
        document.body.style.margin = '0';
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';

        const currentMount = mountRef.current;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        const moleculeGroup = new THREE.Group();
        moleculeGroupRef.current = moleculeGroup;
        scene.add(moleculeGroup);

        // Create initial molecule
        const initialMolecule = createMolecule(isCO2);
        currentMoleculeRef.current = initialMolecule;
        moleculeGroup.add(initialMolecule);

        // Create particles
        const particlesGeometry = new THREE.BufferGeometry();
        const particlesCount = 5000;
        const posArray = new Float32Array(particlesCount * 3);

        for (let i = 0; i < particlesCount * 3; i++) {
            posArray[i] = (Math.random() - 0.5) * 20;
        }

        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.005,
            color: 0xffffff,
        });

        const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
        particlesMesh.position.z = -5;
        moleculeGroup.add(particlesMesh);
        particlesMeshRef.current = particlesMesh;

        // Increase ambient light intensity for smoother overall lighting
        const ambientLight = new THREE.AmbientLight(0x404040, 0.7);
        scene.add(ambientLight);

        // Adjust hemisphere light for softer contrast
        const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.8);
        scene.add(hemisphereLight);

        // Use softer point light and position it for gentler shadows
        const pointLight = new THREE.PointLight(PARTICLE_COLOR, 0.8, 100);
        pointLight.position.set(5, 5, 5);
        scene.add(pointLight);

        // Add a soft directional light for subtle highlights
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3);
        directionalLight.position.set(1, 1, 1);
        scene.add(directionalLight);

        camera.position.z = 5;

        const animate = (time) => {
            requestAnimationFrame(animate);

            if (moleculeGroupRef.current) {
                // Calculate delta time to make rotation frame-rate independent
                const deltaTime = time - lastTimeRef.current;
                lastTimeRef.current = time;

                // Apply rotation based on delta time
                moleculeGroupRef.current.rotation.y += ROTATION_SPEED * deltaTime;
                rotationRef.current.copy(moleculeGroupRef.current.rotation);
            }

            renderer.render(scene, camera);
        };

        animate(0);

        const handleStart = (event) => {
            event.preventDefault();
            if (event.touches) {
                event = event.touches[0];
            }
            isDraggingRef.current = true;
            previousMousePositionRef.current = {
                x: event.clientX,
                y: event.clientY
            };
        };

        const handleEnd = () => {
            isDraggingRef.current = false;
        };

        const handleMove = (event) => {
            event.preventDefault();
            if (!isDraggingRef.current) return;

            if (event.touches) {
                event = event.touches[0];
            }

            const deltaMove = {
                x: event.clientX - previousMousePositionRef.current.x,
                y: event.clientY - previousMousePositionRef.current.y
            };

            const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
                new THREE.Euler(
                    toRadians(deltaMove.y * 0.5), // Reduced sensitivity
                    toRadians(deltaMove.x * 0.5), // Reduced sensitivity
                    0,
                    'XYZ'
                )
            );

            moleculeGroupRef.current.quaternion.multiplyQuaternions(deltaRotationQuaternion, moleculeGroupRef.current.quaternion);

            previousMousePositionRef.current = {
                x: event.clientX,
                y: event.clientY
            };
        };

        // Add event listeners
        currentMount.addEventListener('mousedown', handleStart);
        window.addEventListener('mouseup', handleEnd);
        window.addEventListener('mousemove', handleMove);
        currentMount.addEventListener('touchstart', handleStart);
        window.addEventListener('touchend', handleEnd);
        window.addEventListener('touchmove', handleMove);

        const handleResize = () => {
            const aspect = window.innerWidth / window.innerHeight;
            const fov = aspect > 1 ? 75 : 100; // Wider FOV for mobile
            camera.fov = fov;
            camera.aspect = aspect;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);

            // Adjust molecule size for smaller screens
            const scaleFactor = window.innerWidth < 768 ? 0.7 : 1;
            moleculeGroup.scale.set(scaleFactor, scaleFactor, scaleFactor);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            // Remove event listeners
            currentMount.removeEventListener('mousedown', handleStart);
            window.removeEventListener('mouseup', handleEnd);
            window.removeEventListener('mousemove', handleMove);
            currentMount.removeEventListener('touchstart', handleStart);
            window.removeEventListener('touchend', handleEnd);
            window.removeEventListener('touchmove', handleMove);
            // mountRef.current.removeChild(renderer.domElement);
            if (currentMount) {
                currentMount.removeChild(renderer.domElement);
            }
            document.body.style.margin = '';
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
            scene.remove(moleculeGroup);
            particlesGeometry.dispose();
            particlesMaterial.dispose();
        };
    }, []); // Add isCO2 to the dependency array

    useEffect(() => {
        if (!moleculeGroupRef.current || !currentMoleculeRef.current) return;

        const newMolecule = createMolecule(isCO2);
        const oldMolecule = currentMoleculeRef.current;

        // Apply the stored rotation to the new molecule
        newMolecule.rotation.copy(rotationRef.current);

        // Remove old molecule
        moleculeGroupRef.current.remove(oldMolecule);

        // Add new molecule
        moleculeGroupRef.current.add(newMolecule);

        // Update the current molecule reference
        currentMoleculeRef.current = newMolecule;

        // Dispose of old molecule's geometries and materials
        oldMolecule.traverse((object) => {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
                if (Array.isArray(object.material)) {
                    object.material.forEach(material => material.dispose());
                } else {
                    object.material.dispose();
                }
            }
        });

    }, [isCO2]);

    const handleMoleculeChange = () => {
        setIsCO2(!isCO2);
    };

    // Helper function to convert degrees to radians
    const toRadians = (angle) => {
        return angle * (Math.PI / 180);
    };

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            position: 'relative',
            overflow: 'hidden'
        }}>
            <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />

            <TitleComponent />
            
            {/* Pass SimpleContactButton as a prop to BottomComponent */}
            <BottomComponent 
                isCO2={isCO2} 
                handleMoleculeChange={handleMoleculeChange} 
                contactButton={<SimpleContactButton />}
            />
        </div>
    );
};

export default MoleculeLandingPage;